import PropTypes from 'prop-types'
/* eslint-disable no-restricted-imports */
import { LinkButton as RALinkButton } from '@resident-advisor/design-system'
import { forwardRef, PropsWithChildren } from 'react'
import { hrefAsPropTypes, withLinkWrapper } from '@/components/generic/link'

const LinkButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<LinkButtonProps>
>(({ children, href, ...props }, ref) => (
  <RALinkButton href={href} {...props} data-tracking-id={href} ref={ref}>
    {children}
  </RALinkButton>
))

const linkButtonPropTypes = {
  children: PropTypes.node.isRequired,
  href: hrefAsPropTypes.isRequired,
  replace: PropTypes.bool,
}

LinkButton.propTypes = linkButtonPropTypes

type LinkButtonProps = PropTypes.InferProps<typeof linkButtonPropTypes>

const WrappedLinkButton = withLinkWrapper(LinkButton)

WrappedLinkButton.displayName = 'LinkButton'
export default WrappedLinkButton
