import { PropsWithChildren, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import stickybits from 'stickybits'
import styled from 'styled-components'
import { Box } from '@resident-advisor/design-system'
import { variant as styledVariant } from 'styled-system'
import { zIndex } from '@/themes'
import { useStickyContext } from '@/context/StickyContext'

const Sticky = ({
  children,
  options = {},
  variant = null,
  displayProps = {},
  ...props
}: PropsWithChildren<StickyProps>) => {
  const stickyRef = useRef()
  const { offset } = useStickyContext()

  useEffect(() => {
    stickybits(stickyRef.current, options)
  }, [options, stickyRef])

  return (
    <SectionStyledBox
      variant={variant}
      position="sticky"
      data-testid="sticky"
      ref={stickyRef}
      zIndex={zIndex.stickyGroupHeader}
      top={offset ? `${offset}px !important` : ''}
      /*
       * The title from the listing cards sometimes appears
       * to the left of the sticky header on some screens. Adding
       * pl & ml fixes this.
       */
      pl="5px"
      ml="-5px"
      {...displayProps}
      {...props}
    >
      {children}
    </SectionStyledBox>
  )
}

const stickyPropTypes = {
  options: PropTypes.object,
  variant: PropTypes.string,
  displayProps: PropTypes.object,
}

Sticky.propTypes = stickyPropTypes

type StickyProps = PropTypes.InferProps<typeof stickyPropTypes>

const SectionStyledBox = styled(Box)`
  ${styledVariant({ key: 'section' })};
`

SectionStyledBox.displayName = 'SectionStyledBox'

export default Sticky
