import { useState } from 'react'
import { Box, CloseIcon, RAIcon } from '@resident-advisor/design-system'
import { AnimatePresence } from 'framer-motion'
import cookieNames from 'lib/cookieNames'
import { parseCookies } from 'nookies'
import Modal from '@/components/generic/modal'
import { EaseInFromBottomRightCorner } from '@/components/shared/animation'
import CornerNavToggle from '@/components/navigation/corner-nav/CornerNavToggle'
import MobileNavContent from '@/components/navigation/mobile-nav/MobileNavContent'
import { opacity, zIndex } from '@/themes'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import featureSwitches from '@/enums/feature-switches'
import testIds from '@/enums/testIds'

const CornerNav = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  const cookies = parseCookies()

  if (cookies[cookieNames.displayNav] === 'false') {
    return null
  }

  if (enableNewMainNav) return null

  return (
    <>
      <CornerNavToggle
        onClick={toggleMenu}
        Icon={menuOpen ? CloseIcon : RAIcon}
        menuOpen={menuOpen}
      />
      <AnimatePresence>
        {menuOpen && (
          <Modal
            toggle={toggleMenu}
            backgroundOpacity={opacity.mobileMenuBackground}
            zIndex={zIndex.cornerNavModal}
          >
            <EaseInFromBottomRightCorner>
              <MobileNavContent />
            </EaseInFromBottomRightCorner>
          </Modal>
        )}
      </AnimatePresence>
      {/* Render hidden menu for SEO */}
      {!menuOpen && (
        <Box display="none" data-testid={testIds.cornerNav}>
          <MobileNavContent />
        </Box>
      )}
    </>
  )
}

export default CornerNav
