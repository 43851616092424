import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Alignment, variants } from '@resident-advisor/design-system'
import { getAreaSafeName } from 'lib/utils'
import { setAreaCookie, setEventsAreaCookie } from 'lib/setAreaCookies'
import { useRouter } from 'next/router'
import { queryStringSetRelative } from 'lib/queryStringSet'
import Flag from '@/components/shared/flag'
import { usePersonalizationContext } from '@/context/PersonalizationContext'
import LocationModal from '@/components/shared/location-modal'
import ButtonLink from '@/components/generic/button-link'

const LocationSelector = () => {
  const { loading, area } = usePersonalizationContext()
  const [showModal, setShowModal] = useState(false)
  const { asPath, pathname, replace } = useRouter()

  if (loading) {
    return null
  }

  const toggle = () => setShowModal(!showModal)

  const setLocation = ({ id }) => {
    setAreaCookie(id)
    setEventsAreaCookie(id)
    toggle()
    const path = queryStringSetRelative(asPath, {
      dontScroll: true,
    })
    replace(pathname, path, { scroll: false })
  }

  return (
    <>
      <ButtonLink onClick={toggle} variant={variants.text.small}>
        <Alignment mr={4}>
          <Flag size={16} urlCode={area.country?.urlCode} mr={2} />
          {getAreaSafeName(area, area.country)}
        </Alignment>
      </ButtonLink>
      <AnimatePresence>
        {showModal && (
          <LocationModal
            onLocationSelect={setLocation}
            area={area}
            toggle={toggle}
            showPreciseLocationFilter={false}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default LocationSelector
