import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import getConfig from 'next/config'
import debounce from 'lodash/debounce'
import { Input, variants } from '@resident-advisor/design-system'
import useFocus from '@/hooks/useFocus'
import testIds from '@/enums/testIds'

const FilterModalSearchInput = ({ placeholder, onChange }) => {
  const [searchTerm, setSearchTerm] = useState('')

  // Wait for all animations to finish, then automatically focus the input
  const focusRef = useFocus(false)

  // Need to memoize the debounced function, otherwise it re-renders and loses track of what it's debouncing
  const debouncedChange = useCallback(
    debounce(onChange, publicRuntimeConfig.INPUT_DEBOUNCE_DELAY),
    []
  )

  const handleChange = (event) => {
    setSearchTerm(event.target.value)
    debouncedChange(event.target.value)
  }

  return (
    <Input
      ref={focusRef}
      placeholder={placeholder}
      onChange={handleChange}
      textVariant={variants.text.heading.l}
      value={searchTerm}
      data-testid={testIds.filterModalSearchInput}
    />
  )
}

FilterModalSearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

const { publicRuntimeConfig } = getConfig()

export default FilterModalSearchInput
