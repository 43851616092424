import dynamic from 'next/dynamic'
import { Alignment, Box } from '@resident-advisor/design-system'
import { SVGProps, memo } from 'react'
import { LayoutProps, SpaceProps } from 'styled-system'
import testIds from '@/enums/testIds'

const Flag = memo<FlagProps>(({ urlCode, size = 24, ...props }: FlagProps) => {
  if (!urlCode) {
    return null
  }

  const FlagComponent = dynamic<SVGProps<SVGSVGElement>>(
    // eslint-disable-next-line no-unsanitized/method
    () => import(`./flags/${urlCode.toUpperCase()}.svg`),
    {
      loading: () => (
        <Box flexShrink={0} width={size} height={size} bg="transparent" />
      ),
    }
  )
  if (!FlagComponent) {
    return null
  }

  return (
    <Alignment flexShrink={0} width={size} height={size} {...props}>
      <FlagComponent
        data-testid={testIds.flagSvg}
        name={urlCode}
        width="100%"
        height="100%"
      />
    </Alignment>
  )
})

type FlagProps = {
  urlCode: string
  size?: LayoutProps['width']
  mr?: SpaceProps['mr']
  px?: SpaceProps['px']
}

Flag.displayName = 'Flag'

export default Flag
