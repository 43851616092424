import { getAreaSafeName, generateUrlForAreaAndCountry } from 'lib/utils'
import { eventsByAreaRoute, guideByAreaRoute } from 'lib/routes'
import dict from '@/messages/dict'
import events from '@/messages/events'

const getMobileNavItems = ({
  intl,
  activePath,
  enableNewMainNav,
  area,
  fourthLinkPayloadName,
  fourthLinkPayloadUrl,
}) => {
  const urlForAreaAndCountry = generateUrlForAreaAndCountry('/events', area)
  const eventsLinkProps = area
    ? eventsByAreaRoute(generateUrlForAreaAndCountry('/events', area))
    : { href: '/events' }

  const guideLinkProps = area
    ? guideByAreaRoute(generateUrlForAreaAndCountry('/guide', area))
    : { href: '/guide' }

  const items = {
    [MOBILE_NAV_SECTIONS.home]: [
      {
        title: intl.formatMessage(dict.home),
        href: '/',
      },
    ],
    [MOBILE_NAV_SECTIONS.events]: enableNewMainNav
      ? [
          {
            title: intl.formatMessage(events.events),
            href: urlForAreaAndCountry,
          },
          {
            title: intl.formatMessage(events.forYou),
            href: `${urlForAreaAndCountry}/for-you`,
          },
          {
            title: intl.formatMessage(events.RAPicks),
            href: `${urlForAreaAndCountry}/ra-picks`,
          },
          {
            title: intl.formatMessage(events.festivals),
            href: '/festivals',
          },
        ]
      : [
          {
            title: intl.formatMessage(dict.events),
            ...eventsLinkProps,
          },
          {
            title: getAreaSafeName(area, area?.country),
            ...guideLinkProps,
          },
          {
            title: intl.formatMessage(events.festivals),
            href: '/festivals',
          },
        ],
    [MOBILE_NAV_SECTIONS.music]: [
      {
        title: intl.formatMessage(dict.music),
        href: '/music',
      },
      {
        title: intl.formatMessage(dict.raPodcast),
        href: '/podcast',
      },
      {
        title: intl.formatMessage(dict.reviews),
        href: '/reviews',
      },
      {
        title: intl.formatMessage(dict.mixOfTheDay),
        href: '/mix-of-the-day',
      },
      {
        title: intl.formatMessage(dict.playlists),
        href: '/playlists',
      },
    ],
    [MOBILE_NAV_SECTIONS.magazine]: [
      {
        title: intl.formatMessage(dict.magazine),
        href: '/magazine',
      },
      {
        title: intl.formatMessage(dict.features),
        href: '/features',
      },
      {
        title: intl.formatMessage(dict.films),
        href: '/films',
      },
      {
        title: intl.formatMessage(dict.raExchange),
        href: '/exchange',
      },
      {
        title: intl.formatMessage(dict.news),
        href: '/news',
      },
    ],
    [MOBILE_NAV_SECTIONS.fourthLink]: [
      {
        title: fourthLinkPayloadName,
        href: fourthLinkPayloadUrl,
      },
    ],
  }

  const hrefIsActive = (href) => {
    const hrefPathname = href?.pathname ? href.pathname : href
    return hrefPathname === activePath
  }

  // mark active item
  return enableNewMainNav
    ? items
    : Object.keys(items).reduce(
        (activeItems, key) => ({
          [key]: items[key].map((item) => ({
            ...item,
            active: !item.inactive && hrefIsActive(item.href),
          })),
          ...activeItems,
        }),
        {}
      )
}

const MOBILE_NAV_SECTIONS = {
  home: 'Home',
  events: 'Events',
  music: 'Music',
  magazine: 'Magazine',
  festivals: 'Festivals',
  fourthLink: 'FourthLink',
}

export { MOBILE_NAV_SECTIONS }
export default getMobileNavItems
