import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Alignment, Box, colors } from '@resident-advisor/design-system'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'
import disableScrollbar from 'lib/disableScrollbar'
import { motion } from 'framer-motion'
import { EaseInFromRightSide } from '@/components/shared/animation'
import { FullWidthAbsolutePosition } from '@/components/generic/full-width'
import { usePersonalizationContext } from '@/context/PersonalizationContext'
import {
  useFeatureSwitch,
  useFeatureSwitchData,
} from '@/context/FeatureSwitchesContext'
import featureSwitches from '@/enums/feature-switches'
import useNavLinkTracking from '@/hooks/useNavLinkTracking'
import getMobileNavItems, { MOBILE_NAV_SECTIONS } from './getMobileNavItems'
import MobileNavItem from './mobile-nav-item'
import useHandleScrollMovement from './useHandleScrollMovement'
import NavLinkItem, { NavLinkItemType } from '../global-nav/NavLinkItem'
import { showFeatureSwitchLink } from '../global-nav/GlobalNavBarNew'

const MobileNavItems = () => {
  const { loading, area } = usePersonalizationContext()
  const { pathname: activePath } = useRouter()
  const intl = useIntl()
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  const fourthLinkInGlobalNav = useFeatureSwitchData(
    featureSwitches.enableFourthLinkWithPayloadInGlobalNav
  )
  const showFourthLink = showFeatureSwitchLink(fourthLinkInGlobalNav)

  const navMenuItems = useMemo(
    () =>
      getMobileNavItems({
        intl,
        activePath,
        enableNewMainNav,
        area: loading ? null : area,
        fourthLinkPayloadName: showFourthLink
          ? fourthLinkInGlobalNav.payload.name
          : '',
        fourthLinkPayloadUrl: showFourthLink
          ? fourthLinkInGlobalNav.payload.url
          : '',
      }),
    [
      activePath,
      area,
      loading,
      intl,
      fourthLinkInGlobalNav,
      showFourthLink,
      enableNewMainNav,
    ]
  )

  const sections = [
    MOBILE_NAV_SECTIONS.events,
    MOBILE_NAV_SECTIONS.music,
    MOBILE_NAV_SECTIONS.magazine,
  ]

  if (showFourthLink) {
    sections.push([MOBILE_NAV_SECTIONS.fourthLink])
  }

  return (
    <>
      {!enableNewMainNav && (
        <Alignment pb={4}>
          <EaseInFromRightSide>
            <MobileNavItem
              {...navMenuItems[MOBILE_NAV_SECTIONS.home][0]}
              color={colors.coral}
              index={0}
              length={1}
              variantSize="xl"
            />
          </EaseInFromRightSide>
        </Alignment>
      )}

      {sections.map((section) => (
        <FullWidthAbsolutePosition
          sizes={['s']}
          key={section}
          pb={enableNewMainNav ? 3 : 4}
        >
          <EaseInFromRightSide>
            <MobileMenuContainer navMenuItems={navMenuItems[section]} />
          </EaseInFromRightSide>
        </FullWidthAbsolutePosition>
      ))}
    </>
  )
}

const onComplete = (ref, x) => {
  if (ref.current) {
    /* eslint-disable-next-line no-param-reassign */
    ref.current.style['scroll-snap-type'] = 'x proximity'
    ref.current.scroll(x, 0)
  }
}

const MobileMenuContainer = ({ navMenuItems }) => {
  const [controls, scrollRef, handleScroll] =
    useHandleScrollMovement(onComplete)
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const trackNavClick = useNavLinkTracking()

  if (!enableNewMainNav)
    return (
      <Container>
        <ScrollArea
          ref={scrollRef}
          hasActiveItem={!navMenuItems.some((item) => item.active)}
          animate={controls}
        >
          {navMenuItems.map((item, index) => (
            <Item
              key={index}
              index={index}
              pr={index === navMenuItems.length - 1 ? 3 : 0}
            >
              <MobileNavItem
                key={index}
                index={index}
                length={navMenuItems.length}
                handleItemFocus={handleScroll}
                variantSize="xl"
                onClick={() => trackNavClick(item.href, index)}
                {...item}
              />
            </Item>
          ))}
        </ScrollArea>
      </Container>
    )

  return (
    <Alignment pl={2}>
      <Container>
        {navMenuItems.map((item, index) => (
          <NavLinkItem
            navLinkItemType={
              index === 0
                ? NavLinkItemType.MobileSection
                : NavLinkItemType.MobileSubSection
            }
            key={index}
            href={item.as ?? item.href}
            text={item.title}
            onClick={() => trackNavClick(item.href, index)}
          />
        ))}
      </Container>
    </Alignment>
  )
}

MobileMenuContainer.propTypes = {
  navMenuItems: PropTypes.array.isRequired,
}

const Container = styled.div`
  width: 100vw;
  overflow: hidden;
`

const ScrollArea = styled(motion.div)`
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 54px;
  white-space: nowrap;
  padding-right: 20px;
  scroll-padding-left: 20px;
  padding-left: 20px;

  ${({ hasActiveItem }) =>
    hasActiveItem &&
    css`
      scroll-snap-type: x proximity;
    `};
  ${disableScrollbar}
`

const Item = styled(Box)`
  scroll-snap-align: start;
  display: inline-block;
`

export default MobileNavItems
export { Container }
